import React from 'react'
import styled from 'styled-components'
import {Container, Row, Col} from 'react-bootstrap'
import Button from './Button'

const Paragraph = styled.p`
 @media(max-width: 768px) {
     text-align: center;
 }
`

const Headline = styled.h3`
    font-family: 'Bebas Neue', sans-serif;
    font-size: 35px;
`

const ScreenImage = styled.img`
    max-height: 600px;
    // border: 10px solid #C4C4C4;
    // border-radius: 30px;
    // box-shadow: 10px 10px 20px 0px rgba(0,0,0,0.35);

    @media(max-width: 768px) {
        display: block;
        margin: 0 auto;
        width: 150px;
        height: auto;
    }
`

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;

    @media(max-width: 768px) {
        align-items: center;
        margin-top: 20px;
    }
`

const ContainerBackground = styled.div`
    position: absolute;
    background-color: ${props => props.backgroundColor};
    width: 100%;
    height: 80%;

    @media(max-width: 768px) {
        height: 100%;
    }
`

const SectionContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-bottom: 100px;

    @media(max-width: 768px) {
        padding: 40px 0;
        margin-bottom: 0;
    }
`

const ButtonContainer = styled.div`
    @media(max-width: 768px) {
        align-self: center;
    }
`

const FeatureSection = (props) => {
    return (
        <React.Fragment>
            <SectionContainer>
                {props.backgroundColor && (
                    <ContainerBackground className="background-color" backgroundColor={props.backgroundColor}/>
                )}
            
                <Container style={{position: 'relative'}}>
                    <Row style={{alignItems: 'center'}}>
                        <Col xs={{span: 12, order: 2}} md={{order: props.textLeft ? 1 : 2}}>
                            <TextContainer>
                                <Headline>{props.title}</Headline>
                                <Paragraph>{props.text}</Paragraph>
                                <ButtonContainer>
                                    <Button onClick={props.buttonClick}>Download</Button>
                                </ButtonContainer>
                            </TextContainer>
                        </Col>
                        <Col xs={{span: 12, order: 1}} md={{order: props.textLeft ? 2 : 1}}>
                            <ScreenImage src={props.image}/>
                        </Col>
                    </Row>
                </Container>
            </SectionContainer>
            
        </React.Fragment>
    )
}

export default FeatureSection