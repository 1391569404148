import React from 'react'
import styled from 'styled-components'
import StripeForm from './stripe/StripeForm';
import {Container} from 'react-bootstrap'
import {connect} from 'react-redux'
import CloseButton from '../Components/CloseButton'

const SubscriptionPage = (props) => {
    return (
        <Container style={{marginTop: 10}}>
                <CloseButton onClick={props.hideForm} color="#1E1E1E"/>
                <EntryContainer>
                    <StripeForm 
                        unlockIntroPricing={()=>props.unlockIntroPricing()}
                        unlockHalfOffPricing={()=>props.unlockHalfOffPricing()}
                        resetPricing={()=>props.resetPricing()}
                    />
                </EntryContainer>
        </Container>
    )
}

const EntryContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 15px;
`

const mapStateToProps = (state) => {
    return {
        profile: state.user.profile,
        subscribed: state.user.subscribed
    }
}

export default connect(mapStateToProps)(SubscriptionPage)
