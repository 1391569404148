import React, {useState, useEffect} from 'react';
import styled from 'styled-components'
import Header from './Components/Header'
import Footer from './Components/Footer'
import Home from './Components/Home'
import AccountPage from './Components/AccountPage'
import AdminPage from './Components/AdminPage'
import {connect} from 'react-redux'
import {checkSubscriptionStatus, fetchUserProfile, setAuthLoaded, setActivePlanChoice} from './store/actions/userActions.js'
import {Modal, Container} from 'react-bootstrap'
import LoginForm from './Components/LoginForm'
import SignUpForm from './Components/SignUpForm'
import SubscribePage from './Components/SubscriptionPage'
import ForgotPasswordForm from './Components/ForgotPasswordForm'
import {BrowserRouter as Router,Switch, Route} from "react-router-dom";
import { useHistory } from "react-router-dom";
import firebase from 'firebase/app'
import SUBSCRIPTION_MAP from './Components/stripe/SubscriptionMap'

function App(props) {
  const [loginModalActive, setLoginModalActive] = useState(false)
  const [signUpModalActive, setSignUpModalActive] = useState(false)
  const [forgotPasswordModalActive, setForgotPasswordModalActive] = useState(false)
  const [subscribeModalActive, setSubscribeModalActive] = useState(false)
  const [showSubscribeScreenAfterAuth, setShowSubscribeScreenAfterAuth] = useState(false)
  const {uid, fetchUserProfile, subscribeData, profile} = props
  const history = useHistory()


  firebase.auth().onAuthStateChanged(function(user) {
    if (!props.authLoaded) {
      props.setAuthLoaded(true)
    }
  });
  
  useEffect(()=>{
    if (uid && Object.keys(profile).length < 1) {
      fetchUserProfile(uid)
    }
    if (uid && !subscribeData) {
      checkSubscriptionStatus()
    }
    if (uid && subscribeData && showSubscribeScreenAfterAuth) {
      setSubscribeModalActive(true)
      setShowSubscribeScreenAfterAuth(false)
    }
  }, [uid, fetchUserProfile, subscribeData, profile])
  
  const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1];
  if (path) {
    history.replace(path[1])
  }

  const unlockIntroPricing = () => {
    console.log('active plan choice: ', props.activePlanChoice)
    if (props.activePlanChoice.planType === 'annual') {
      props.setActivePlanChoice({priceId: SUBSCRIPTION_MAP['annualIntro'], planName: 'Annual: Discounted', priceText: '$59.99', price: 59.99, planType: 'annual'})
    }
    else if (props.activePlanChoice.planType === 'monthly') {
      props.setActivePlanChoice({priceId: SUBSCRIPTION_MAP['monthlyIntro'], planName: 'Monthly: Discounted', priceText: '$7.99', price: 7.99, planType: 'monthly'})
    }
  }

  const unlockHalfOffPricing = () => {
    if (props.activePlanChoice.planType === 'annual') {
      props.setActivePlanChoice({priceId: SUBSCRIPTION_MAP['annualHalf'], planName: 'Annual: 50% Off', priceText: '$39.99', price: 39.99, planType: 'annual'})
    }
    else if (props.activePlanChoice.planType === 'monthly') {
      props.setActivePlanChoice({priceId: SUBSCRIPTION_MAP['monthlyHalf'], planName: 'Monthly: 50% Off', priceText: '$4.99', price: 4.99, planType: 'monthly'})
    }
  }

  const resetPricing = () => {
    if (props.activePlanChoice.planType === 'annual') {
      props.setActivePlanChoice({priceId: SUBSCRIPTION_MAP['annual'], planName: 'Annual', priceText: '$79.99', price: 79.99, planType: 'annual'})
    }
    else if (props.activePlanChoice.planType === 'monthly') {
      props.setActivePlanChoice({priceId: SUBSCRIPTION_MAP['monthly'], planName: 'Monthly', priceText: '$9.99', price: 9.99, planType: 'monthly'})
    }
  }


  return (
    <AppWrapper>
      <Router>
        <Header showLoginModal={()=>setLoginModalActive(true)}/>
        <Switch>
          <Route exact path="/">
            <Home 
              showLoginModal={()=>setLoginModalActive(true)}
              setLoginModalActive={(state)=>{setLoginModalActive(state)}}
              setShowSubscribeScreenAfterAuth={(state)=>{setShowSubscribeScreenAfterAuth(state)}}
              setSubscribeModalActive={(state) => setSubscribeModalActive(state)}
              openSignUpForm={()=>setSignUpModalActive(true)}
            />
            <Modal show={loginModalActive} onHide={()=>setLoginModalActive(false)}>
                <LoginForm
                  hideForm={()=>setLoginModalActive(false)}
                  openSignUpForm={()=>{setLoginModalActive(false); setSignUpModalActive(true)}}
                  openForgotPasswordModal={()=>{setLoginModalActive(false); setForgotPasswordModalActive(true)}}
                />
            </Modal>
            <Modal show={signUpModalActive} onHide={()=>setSignUpModalActive(false)}>
              <SignUpForm
                hideForm={()=>{setSignUpModalActive(false)}}
                openLoginForm={()=>{setSignUpModalActive(false); setLoginModalActive(true)}}
              />
            </Modal>
            <Modal show={forgotPasswordModalActive} onHide={()=>setForgotPasswordModalActive(false)}>
              <ForgotPasswordForm
                hideForm={()=>{setForgotPasswordModalActive(false)}}
              />
            </Modal>
            <Modal show={subscribeModalActive} onHide={()=>setSubscribeModalActive(false)}>
              <SubscribePage 
                hideForm={()=>setSubscribeModalActive(false)}
                unlockIntroPricing={()=>unlockIntroPricing()}
                unlockHalfOffPricing={()=>unlockHalfOffPricing()}
                resetPricing={()=>resetPricing()}
              />
            </Modal>
          </Route>
          <Route path="/account">
            <AccountPage/>
          </Route>
          <Route path="/admin">
            <AdminPage/>
          </Route>
          <Route component={NoMatch}/>
        </Switch>
      </Router>
      <Footer/>
    </AppWrapper>
  );
}

const NoMatch = (props) => {
  return (
    <Container>
      <h1>404 - Not Found</h1>
    </Container>
  )
}

const AppWrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`

const mapStateToProps = (state) => {
  return {
    uid: state.firebaseReducer.auth.uid,
    subscribed: state.user.subscribeStatus,
    subscribeData: state.user.subscribeData,
    profile: state.user.profile,
    authLoaded: state.user.authLoaded,
    activePlanChoice: state.user.activePlanChoice
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUserProfile: (uid) => dispatch(fetchUserProfile(uid)),
    checkSubscriptionStatus: (uid) => dispatch(checkSubscriptionStatus(uid)),
    setAuthLoaded: (loaded) => dispatch(setAuthLoaded(loaded)),
    setActivePlanChoice: (plan) => dispatch(setActivePlanChoice(plan))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
