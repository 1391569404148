import React from 'react'
import styled from 'styled-components'
import {Link, useHistory} from 'react-router-dom'
import {connect} from 'react-redux'
import {signOut} from '../store/actions/userActions'

const Header = (props) => {

    const history = useHistory()
    
    return (
        <HeaderContainer>
            <Link to="/">
                <HeaderImage src={require('../assets/img/poddecks-logo.png')}/>
            </Link>
            <RightLinks>
                {props.loggedIn ? (
                    <React.Fragment>
                        {props.admin && (
                            <Link style={{marginLeft: 'auto', color: '#FEB802', marginRight: 20, fontFamily: "'Bebas Neue', sans-serif"}} to="/admin">Admin</Link>    
                        )}
                        <Link style={{marginLeft: 'auto', color: '#FEB802', marginRight: 20, fontFamily: "'Bebas Neue', sans-serif"}} to="/account">Account</Link>
                        <HeaderButton onClick={()=>{props.signOut(); history.push('/')}}>Sign Out</HeaderButton>
                    </React.Fragment>
                ) : (
                    <HeaderButton style={{marginLeft: 'auto'}} onClick={props.showLoginModal}>Log In</HeaderButton>
                )}
            </RightLinks>
        </HeaderContainer>
    )
}

const HeaderContainer = styled.div`
    width: 100%;
    background-color: #1E1E1E;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`

const HeaderImage = styled.img`
    max-width: 100px;
    margin-left: 20px;
`

const RightLinks = styled.div`
    margin-left: auto;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    display: none;
`

const HeaderButton = styled.a`
    // margin-left: auto;
    background-color: #FEB802;
    color: #000;
    font-family: 'Bebas Neue', sans-serif;
    height: 100%;
    padding: 10px 25px;
    cursor: pointer;

    &:hover {
        text-decoration: none;
        color: #000;
    }
`

const mapStateToProps = (state) => {
    return {
        loggedIn: state.firebaseReducer.auth.uid,
        admin: state.user.profile.admin
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        signOut: () => dispatch(signOut())
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Header)