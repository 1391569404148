import React from 'react'
import styled from 'styled-components'
// import Button from '../Components/Button'
import AppStoreIcon from '../assets/img/app-store-icon.svg'
import PlayIcon from '../assets/img/google-play-badge_small.png'
import YouTube from 'react-youtube'
import ReactGA from 'react-ga';

const Header = (props) => {
    const logEvent = (action, category) => {
        ReactGA.event({
            category: category,
            action: action
        });
        window.fbq('trackCustom', action)
    }

    return (
        <HeroContainer>
            <HeroImage src={props.img}/>
            <MobileHeroImage src={props.mobileImg}/>
            <ContentContainer>
                <Title>Interview Like A&nbsp;Legend</Title>
                <Description>Pod Decks is the #1 tool for podcasters, YouTubers, and content creators to have deeper conversations, get more engagement, and take their podcast to the next level.</Description>
                    {/* <Button style={{alignSelf: 'center', marginBottom: '1.5rem'}} onClick={props.startFreeTrialClick}>{props.returningUser ? 'Subscribe' : 'Start Free Trial'}</Button> */}
                    <Video
                        videoId="jIWYNSsj-3o"
                        onPlay={()=>logEvent('VideoStarted', 'Content')}
                        onEnd={()=>logEvent('VideoEnded', 'Content')}
                    />
                    <AppStoreLinkContainer>
                        <AppStoreLink href="https://apps.apple.com/us/app/id1514611595" onClick={()=>logEvent('AppleAppStoreClick', 'User')} target="_blank" rel="noopener noreferrer"><AppStoreImage alt="Download on the App Store" src={AppStoreIcon}/></AppStoreLink>
                        <AppStoreLink href="https://play.google.com/store/apps/details?id=com.poddecks" onClick={()=>logEvent('GooglePlayStoreClick', 'User')} target="_blank" rel="noopener noreferrer"><PlayStoreImage alt="Get on Google Play" src={PlayIcon}/></AppStoreLink>
                    </AppStoreLinkContainer>
                    
            </ContentContainer>
        </HeroContainer>
    )
}

const HeroContainer = styled.div`
    width: 100%;
    max-height: 750px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    min-height: 700px;

    @media(max-width: 768px) {
        min-height: 650px;

        &:after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0,0,0,.37);
        }
    }
`

const Video = styled(YouTube)`
    width: 640px;

    @media(max-width: 900px) {
        width: 500px;
        height: 281px;
    }

    @media(max-width: 600px) {
        width: 400px;
        height: 225px;
    }

    @media(max-width: 400px) {
        width: 325px;
        height: 183px;
    }
`


const HeroImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50%;
    margin-top: -2px;
    position: absolute;

    @media(max-width: 768px) {
        display: none;
    }
`

const MobileHeroImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: Cover;
    object-positin: 50%;
    position: absolute;

    @media(min-width: 768px) {
        display: none;
    }
`

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 2;
    

    @media(max-width: 768px) {
        align-self: center;
        margin-bottom: 40px;
    }
`

const Title = styled.h1`
    color: #fff;
    text-align: center;
    font-family: 'Bebas Neue', sans-serif;
    font-size: 65px;
    line-height: 1;
    max-width: 575px;

    @media(max-width: 768px) {
        font-size: 50px;
    }
`

const Description = styled.p`
    color: #fff;
    text-align: center;
    font-size: 18px;
    font-weight: 300;
    max-width: 700px;

    @media(max-width: 2080px) {
        max-width: 600px;
    }

    @media(max-width: 1600px) {
        max-width: 500px;
    }

    @media(max-width: 768px) {
        font-size: 15px;
        font-weight: bold;
        padding: 0 10px;
    }
`

const AppStoreLinkContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    @media(max-width: 425px) {
        flex-direction: column;
    }
`

const AppStoreLink = styled.a`
    margin-top: 0rem;

    @media(max-width: 425px) {
        margin-top: .5rem;
    }
`

const AppStoreImage = styled.img`
    width: 11rem;
`

const PlayStoreImage = styled.img`
    width: 14rem;

    @media(max-width: 425px) {
        width: 13rem;
    }
`

export default Header