import React from 'react'
import {Container, Row, Col} from 'react-bootstrap'
import styled from 'styled-components'

const FeatureContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100%;

    @media(max-width: 768px) {
        margin-bottom: 20px;
    }
`

const Title = styled.h2`
    font-family: 'Bebas Neue', sans-serif;
    margin: 20px 0;

    @media(max-width: 768px) {
        margin-top: 10px;
        margin-bottom: 5px;
    }
`

const Description = styled.p`
    text-align: center
`

const ImageContainer = styled.div`
    width: 200px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media(max-width: 768px) {
        width: 125px;
        height: 125px;
    }
`

const Image = styled.img`
    width: 100%;
    height: 100%;
    object-fit: contain;
`

const FeatureWrapper = styled.div`
    padding: 60px 0;
    
    @media(max-width: 768px) {
        padding: 20px 0;
    }
`


const Features = (props) => {
    return (
        <Container>
            <FeatureWrapper>
                <Row>
                    {props.data.map((feature, index) =>
                        <Col key={index} sm={12} md={4}>
                            <FeatureContainer>
                                <ImageContainer>
                                    <Image src={feature.img}></Image>
                                </ImageContainer>
                                <Title>{feature.title}</Title>
                                <Description>{feature.description}</Description>
                            </FeatureContainer>
                        </Col>
                    )}
                </Row>
            </FeatureWrapper>
        </Container>
    )
}

export default Features