import React, {useRef, useState, useEffect} from 'react'
import HeroBanner from './HeroBanner'
import AsSeenOn from './AsSeenOn'
import FeatureBullets from './FeatureBullets'
import FeatureSection from './FeatureSection'
import DownloadSection from './DownloadSection'
// import SubscribeSection from './SubscriptionSection' 
import {useHistory} from 'react-router-dom'
import {setActivePlanChoice} from '../store/actions/userActions'
import {connect} from 'react-redux'
import animateScrollTo from 'animated-scroll-to';
import ReactGA from 'react-ga';


//ASSETS
import HeaderImage from '../assets/img/homepage-hero.png'
import MobileHeaderImage from '../assets/img/homepage-hero-mobile.png'
import LibraryImage from '../assets/img/library-page.png'
import ToolboxImage from '../assets/img/toolbox-page.png'
import MemberExclusiveImage from '../assets/img/member-exclusive-page.png'
import ExploreAndLearnImage from '../assets/img/explore-and-learn-page.png'
import BoostEngagementImage from '../assets/img/boost-engagement.png'
import BreakTheIceImage from '../assets/img/break-the-ice.png'
import GrowYourAudienceImage from '../assets/img/grow-your-audience.png'
import EOFLogo from '../assets/img/eof-logo.png'
import PodcastMovementLogo from '../assets/img/podcast-movement-logo.png'
import SounderLogo from '../assets/img/sounder-logo.png'
import PodfestLogo from '../assets/img/podfest-logo.png'


const Home = (props) => {

    const history = useHistory()
    // const subscribeSectionRef = useRef(null)
    const downloadSectionRef = useRef(null)
    const [returningUser, setReturningUser] = useState(false)

    useEffect(()=>{
        if (props.uid && props.subscribeData) {
            if (props.subscribeData.subscriber) {
                setReturningUser(props.subscribeData.subscriber.entitlements.full_access ? true : false)
            }
            else {
                setReturningUser(false)
            }
        }
        else {
            setReturningUser(false)
        }
    }, [props.subscribeData, props.uid])
    
    const bulletsData = [
        {
            img: BreakTheIceImage,
            title: 'Break the Ice',
            description: 'Unique ideas for episodes and podcast interview questions that will set you apart from the crowd.',
        },
        {
            img: BoostEngagementImage,
            title: 'Boost Engagement',
            description: 'Asking questions is proven to be the #1 strategy to increase social media engagement and interactions.',
        },
        {
            img: GrowYourAudienceImage,
            title: 'Grow Your Audience',
            description: 'Create incredible content that will get you more downloads and new subscribers.',
        }
    ]

    const asSeenOnData = [
        {
            name: 'Entrepreneur on Fire',
            src: EOFLogo
        },
        {
            name: 'Podcast Movement',
            src: PodcastMovementLogo
        },
        {
            name: 'Sounder.fm',
            src: SounderLogo
        },
        {
            name: 'Podfest',
            src: PodfestLogo,
            width: "125px"
        },
    ]

    // const startFreeTrialClick = () => {
    //     animateScrollTo(subscribeSectionRef.current.offsetTop)
    //     ReactGA.event({
    //         category: 'User',
    //         action: 'Free Trial Scroll Click'
    //     });
    // }

    const downloadButtonClick = () => {
        animateScrollTo(downloadSectionRef.current.offsetTop)
        ReactGA.event({
            category: 'User',
            action: 'Download Button Click'
        });
        window.fbq('trackCustom', 'DownloadButtonClick')
    }

    const initiateSubscriptionPurchase = (option) => {
        ReactGA.event({
            category: 'User',
            action: 'Initiate Subscription Purchase'
        });
        props.setActivePlanChoice(option)
        if (props.uid && props.subscribed) {
            history.push('/account')
        }
        else if (!props.uid) {        
            props.openSignUpForm(true)
            props.setShowSubscribeScreenAfterAuth(true)
        }
        else if (props.uid && !props.subscribed) {
            props.setSubscribeModalActive(true)
        }
    }
    

    return (
        <React.Fragment>
            <HeroBanner 
                img={HeaderImage}
                mobileImg={MobileHeaderImage}
                returningUser={returningUser}
            />
            <AsSeenOn data={asSeenOnData}/>
            <FeatureBullets data={bulletsData}/>
            <FeatureSection
                title="Our Entire Pod Decks Library"
                text="Change the way you interview with over 3000 unique questions to engage your guests and podcast audience."
                image={LibraryImage}
                textRight
                buttonClick={downloadButtonClick}
                returningUser={returningUser}
            />
            <FeatureSection
                title="Explore and Learn"
                text="Elevate your podcasting knowledge with world renowned experts in the comfort of your home with our powerful podcasting training courses."
                image={ExploreAndLearnImage}
                textLeft
                backgroundColor="#F1F1F1"
                buttonClick={downloadButtonClick}
                returningUser={returningUser}
            />
            <FeatureSection
                title="Podcasting Toolbox"
                text="All of our favorite podcasting tools, accessories, software, and courses in one place. Find your next podcasting tool now!"
                image={ToolboxImage}
                textRight
                buttonClick={downloadButtonClick}
                returningUser={returningUser}
            />
            <FeatureSection
                title="Member Exclusives"
                text="Get access to member exclusive content like monthly podcast gear giveaways, royalty free music library, podcast sponsorship, and our private community."
                image={MemberExclusiveImage}
                textLeft
                backgroundColor="#F1F1F1"
                buttonClick={downloadButtonClick}
                returningUser={returningUser}
            />
            <div ref={downloadSectionRef}></div>
            <DownloadSection/>
            {/* <div ref={subscribeSectionRef}></div>
            <SubscribeSection
                initiateSubscriptionPurchase={(option)=>initiateSubscriptionPurchase(option)}
                returningUser={returningUser}
            /> */}
            
        </React.Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        uid: state.firebaseReducer.auth.uid,
        subscribed: state.user.subscribeStatus,
        subscribeData: state.user.subscribeData
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setActivePlanChoice: (plan) => dispatch(setActivePlanChoice(plan))
    }
  }


export default connect(mapStateToProps, mapDispatchToProps)(Home)
