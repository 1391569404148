const initState = {
    subscribeStatus: false,
    subscribeData: {},
    profile: {},    
    activePlanChoice: {},
    authLoaded: false,
    admin: false
}

const subscription = (state = initState, action) => {
    switch (action.type) {
      case 'SET_SUBSCRIPTION_STATUS':
        return {
            ...state,
            subscribeStatus: action.subscribed
        }
      case 'SET_SUBSCRIPTION_DATA': {
        return {
          ...state,
          subscribeData: action.payload
        }
      }
      case 'SET_USER_PROFILE':
        return {
          ...state,
          profile: action.payload
        }
      case 'SET_ACTIVE_PLAN_CHOICE':
        console.log('plan choice: ', action.plan)
        return {
          ...state,
          activePlanChoice: action.plan
        }
      case 'SIGN_OUT':
        return {
          ...initState,
          authLoaded: true
        }
      case 'SET_AUTH_LOADED':
        return {
          ...state,
          authLoaded: action.loaded 
        }
      default:
        return state
    }
  }
  
  export default subscription