import React, {useState} from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import Button from './Button'
import {sendForgotPasswordEmail} from '../store/actions/userActions'
import CloseButton from '../Components/CloseButton'

const LoginForm = (props) => {
    const [email, setEmail] = useState('')
    const [error, setError] = useState('')

    const handleSubmit = async (e) => {
        e.preventDefault()
        await props.sendForgotPasswordEmail(email)
        .then( resp => {
            if (resp.success) {
                props.hideForm()
            }
        })
        .catch(error => setError(error))
    }

    return (
        <Wrapper>
            <CloseButton onClick={props.hideForm}/>
            <Container>
                <Title>Reset Password</Title>
                <Description>Please enter your email address below.</Description>
                <Form onSubmit={(e)=>handleSubmit(e)}>
                    <Input type="email" placeholder="Email" onChange={(e)=>{setEmail(e.target.value)}} required/>
                    <Button fullWidth><SubmitInput type="submit" value="Send Password Reset Email"/></Button>
                    <Error>{error}</Error>
                </Form>
            </Container>
        </Wrapper>
    )
}

const Title = styled.h3`
    font-size: 45px;
    margin-bottom: 10px;
    color: #fff;
    font-family: 'Bebas Neue', sans-serif;
    text-align: center;
`

const Wrapper = styled.div`
    padding: 20px;
    background-color: #1E1E1E;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const Container = styled.div`
    display: flex;
    flex-direction: column;
    aling-items: center;
    justicy-content: center;
    max-width: 300px
`

const Description = styled.p`
    color: #fff;
    font-weight: 300;
    text-align: center;
`

const Form = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const Input = styled.input`
    color: #000;
    background: #fff;
    border-radius: 5px;
    width: 100%;
    padding: 10px;
    border: none;
    margin-bottom: 20px;

    &:focus {
        outline: none;
    }
`
const SubmitInput = styled.input`
    background-color: transparent;
    border: none;

    &:focus {
        outline: none;
    }
`

const Error = styled.p`
    color: red;
    font-size: 13px;
    margin-top: 10px;
    margin-bottom: 0;
`


const mapDispatchToProps = (dispatch) => {
    return {
        sendForgotPasswordEmail:(email) => dispatch(sendForgotPasswordEmail(email)),
    }
}

export default connect(null, mapDispatchToProps)(LoginForm)