import React from 'react'
import styled from 'styled-components'

const CloseButton = (props) => {
    return (
        <Container onClick={props.onClick} type="button" className="close" data-dismiss="modal" aria-label="Close">
            <Icon aria-hidden="true" color={props.color}>&times;</Icon>
        </Container>
    )
}

const Container = styled.button `
    position: absolute;
    right: 1rem;
    top: 1rem;
    background: none;
    outline: none;
    border: none;
    transition: opacity .2s ease-out;

    &:focus {
        outline: none;
    }
    
`

const Icon = styled.span`
    font-size: 2rem;
    color: ${props => props.color ? props.color : "#fff"};
`

export default CloseButton