import React from 'react'
import styled, {keyframes} from 'styled-components'

export const GoogleIcon = (props) => {
    return (
        <svg width={props.size ? props.size : 20} height={props.size ? props.size : 20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0)">
                <path d="M4.43242 12.0862L3.73625 14.6851L1.19176 14.739C0.431328 13.3285 0 11.7148 0 9.99997C0 8.34173 0.403281 6.77798 1.11812 5.40106H1.11867L3.38398 5.81637L4.37633 8.06809C4.16863 8.6736 4.05543 9.3236 4.05543 9.99997C4.05551 10.734 4.18848 11.4374 4.43242 12.0862Z" fill="#FFA500"/>
                <path d="M19.8253 8.13187C19.9401 8.73679 20 9.36152 20 9.99999C20 10.7159 19.9247 11.4143 19.7813 12.0879C19.2945 14.3802 18.0225 16.3819 16.2605 17.7984L16.2599 17.7978L13.4066 17.6522L13.0028 15.1313C14.172 14.4456 15.0858 13.3725 15.5671 12.0879H10.2198V8.13187H15.6451H19.8253Z" fill="#FFA500"/>
                <path d="M16.2599 17.7979L16.2604 17.7984C14.5467 19.1759 12.3698 20 10 20C6.19178 20 2.8808 17.8715 1.19178 14.7391L4.43244 12.0863C5.27693 14.3402 7.45111 15.9446 10 15.9446C11.0956 15.9446 12.122 15.6484 13.0028 15.1314L16.2599 17.7979Z" fill="#FFA500"/>
                <path d="M16.383 2.30219L13.1434 4.95437C12.2319 4.38461 11.1544 4.05547 9.99999 4.05547C7.39339 4.05547 5.17854 5.73348 4.37636 8.06812L1.11866 5.40109H1.11811C2.78241 2.1923 6.13515 0 9.99999 0C12.4264 0 14.6511 0.864297 16.383 2.30219Z" fill="#FFA500"/>
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="20" height="20" fill="#FFA500"/>
                </clipPath>
            </defs>
        </svg>
    )
}

export const FacebookIcon = (props) => {
    return (
        <svg width={props.size ? props.size : 20} height={props.size ? props.size : 20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.25 6.875V4.375C11.25 3.685 11.81 3.125 12.5 3.125H13.75V0H11.25C9.17875 0 7.5 1.67875 7.5 3.75V6.875H5V10H7.5V20H11.25V10H13.75L15 6.875H11.25Z" fill="#FFA500"/>
        </svg>

    )
}

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const Loading = styled.div`
    border: 4px solid #f3f3f3; /* Light grey */
    border-top: 4px solid #141414; /* Blue */
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: ${spin} 1s linear infinite;
`

export const LoadingIcon = (props) => {
    return (
        <Loading></Loading>
    )
}

export const EyeIcon = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none"/><path fill={props.color ? props.color : "#000"} d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z"/></svg>
    )
}

export const CheckMarkIcon = (props) => {
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" height={props.size ? props.size : "24"} viewBox="0 0 24 24" width={props.size ? props.size : "24"}><path d="M0 0h24v24H0z" fill="none"/><path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z" fill={props.color ? props.color : "#FEB802"}/></svg>
    )
}

export const InstagramIcon = (props) => {
    return (
        <svg width={props.size ? props.size : 24} height={props.size ? props.size : 24} role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>Instagram icon</title><path fill={props.color ? props.color : "#fff"} d="M12 0C8.74 0 8.333.015 7.053.072 5.775.132 4.905.333 4.14.63c-.789.306-1.459.717-2.126 1.384S.935 3.35.63 4.14C.333 4.905.131 5.775.072 7.053.012 8.333 0 8.74 0 12s.015 3.667.072 4.947c.06 1.277.261 2.148.558 2.913.306.788.717 1.459 1.384 2.126.667.666 1.336 1.079 2.126 1.384.766.296 1.636.499 2.913.558C8.333 23.988 8.74 24 12 24s3.667-.015 4.947-.072c1.277-.06 2.148-.262 2.913-.558.788-.306 1.459-.718 2.126-1.384.666-.667 1.079-1.335 1.384-2.126.296-.765.499-1.636.558-2.913.06-1.28.072-1.687.072-4.947s-.015-3.667-.072-4.947c-.06-1.277-.262-2.149-.558-2.913-.306-.789-.718-1.459-1.384-2.126C21.319 1.347 20.651.935 19.86.63c-.765-.297-1.636-.499-2.913-.558C15.667.012 15.26 0 12 0zm0 2.16c3.203 0 3.585.016 4.85.071 1.17.055 1.805.249 2.227.415.562.217.96.477 1.382.896.419.42.679.819.896 1.381.164.422.36 1.057.413 2.227.057 1.266.07 1.646.07 4.85s-.015 3.585-.074 4.85c-.061 1.17-.256 1.805-.421 2.227-.224.562-.479.96-.899 1.382-.419.419-.824.679-1.38.896-.42.164-1.065.36-2.235.413-1.274.057-1.649.07-4.859.07-3.211 0-3.586-.015-4.859-.074-1.171-.061-1.816-.256-2.236-.421-.569-.224-.96-.479-1.379-.899-.421-.419-.69-.824-.9-1.38-.165-.42-.359-1.065-.42-2.235-.045-1.26-.061-1.649-.061-4.844 0-3.196.016-3.586.061-4.861.061-1.17.255-1.814.42-2.234.21-.57.479-.96.9-1.381.419-.419.81-.689 1.379-.898.42-.166 1.051-.361 2.221-.421 1.275-.045 1.65-.06 4.859-.06l.045.03zm0 3.678c-3.405 0-6.162 2.76-6.162 6.162 0 3.405 2.76 6.162 6.162 6.162 3.405 0 6.162-2.76 6.162-6.162 0-3.405-2.76-6.162-6.162-6.162zM12 16c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm7.846-10.405c0 .795-.646 1.44-1.44 1.44-.795 0-1.44-.646-1.44-1.44 0-.794.646-1.439 1.44-1.439.793-.001 1.44.645 1.44 1.439z"/></svg>
    )
}

export const FacebookIconSmall = (props) => {
    return (
        <svg width={props.size ? props.size : 24} height={props.size ? props.size : 24} role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>Facebook icon</title><path fill={props.color ? props.color : "#fff"} d="M23.9981 11.9991C23.9981 5.37216 18.626 0 11.9991 0C5.37216 0 0 5.37216 0 11.9991C0 17.9882 4.38789 22.9522 10.1242 23.8524V15.4676H7.07758V11.9991H10.1242V9.35553C10.1242 6.34826 11.9156 4.68714 14.6564 4.68714C15.9692 4.68714 17.3424 4.92149 17.3424 4.92149V7.87439H15.8294C14.3388 7.87439 13.8739 8.79933 13.8739 9.74824V11.9991H17.2018L16.6698 15.4676H13.8739V23.8524C19.6103 22.9522 23.9981 17.9882 23.9981 11.9991Z"/></svg>
    )
}

export const YouTubeIcon = (props) => {
    return (
        <svg width={props.size ? props.size : 24} height={props.size ? props.size : 24} role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>YouTube icon</title><path fill={props.color ? props.color : "#fff"} d="M23.495 6.205a3.007 3.007 0 0 0-2.088-2.088c-1.87-.501-9.396-.501-9.396-.501s-7.507-.01-9.396.501A3.007 3.007 0 0 0 .527 6.205a31.247 31.247 0 0 0-.522 5.805 31.247 31.247 0 0 0 .522 5.783 3.007 3.007 0 0 0 2.088 2.088c1.868.502 9.396.502 9.396.502s7.506 0 9.396-.502a3.007 3.007 0 0 0 2.088-2.088 31.247 31.247 0 0 0 .5-5.783 31.247 31.247 0 0 0-.5-5.805zM9.609 15.601V8.408l6.264 3.602z"/></svg>
    )
}