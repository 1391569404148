import React from 'react'
import styled from 'styled-components'

const AsSeenOn = (props) => {
    return (
        <Container>
            <Text>As Seen On:</Text>
            <ImageContainer>
                {props.data.map(image => 
                    <Image src={image.src} alt={image.name} title={image.name} imageWidth={image.width}/>    
                )}
            </ImageContainer>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background: #F1F1F1;
`

const ImageContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 700px;

    @media(max-width: 700px) {
        flex-direction: column;
        width: 100%;
    }
`

const Text = styled.h2`
    text-transform: uppercase;
    font-size: 25px;
    color: #666;
    font-family: 'Bebas Neue', sans-serif;

    @media(max-width: 768px) {
        margin-bottom: 25px;
    }
`

const Image = styled.img`
    width: ${props => props.imageWidth ? props.imageWidth : "150px"};
    height: auto;
    margin-right: 30px;

    @media(max-width: 768px) {
        margin-right: 0;
        margin-bottom: 40px;
    }
`

export default AsSeenOn