import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import {Container} from 'react-bootstrap'
import {connect} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {fetchContestList, fetchContestData} from '../store/actions/userActions.js'
import NotificationManager from 'react-notifications/lib/NotificationManager'
import Button from './Button'
import copy from 'copy-to-clipboard'


const AdminPage = (props) => {
    const [activeSection, setActiveSection] = useState('contests')
    const [activeContest, setActiveContest] = useState('')
    const [contestList, setContestList] = useState({})
    const [contestResults, setContestResults] = useState([])

    const history = useHistory()

    const handleContestSelect = (e) => {
        setActiveContest(e.target.value)
    }

    const handleContestCopy = async () => {
        setContestResults([])
        try {
            const results = await props.fetchContestData(activeContest)
            let textToCopy = ''
            //FORMAT RESULTS FOR CLIPBOARD
            results.success.forEach(entry => {
                if (entry.email || entry.name) {
                    textToCopy = textToCopy + `${entry.email  || '[No Email On File]'} ${entry.name ? ' - ' + entry.name : ''}\n`
                }
                else {
                    textToCopy = textToCopy + "[No Email Or Name On File]" +"\n"
                }
            })
            copy(textToCopy)
            setContestResults(results.success)

        }
        catch(e) {
            console.log(e)
            NotificationManager.error('Error', 'Error fetching contest results')
        }
    }

    useEffect(()=>{
        const handleFetchContestList = async () => {
            try {
                const status = await props.fetchContestList()
                if (status.success) {
                    setContestList(status.success)
                    setActiveContest(status.success[0])
                }
            }
            catch(err) {
                NotificationManager.error('Error fetching contest data.')
            }
        }
        
        if (!props.admin && props.authLoaded) {
            history.push('/')
        }
        else {
            handleFetchContestList()
        }
    }, [props.authLoaded])

    return (
        <React.Fragment>
            {props.admin && (
                <Container style={{marginTop: 20}} fluid>
                    <Title>Admin</Title>
                        <SideBar>
                            <SideBarSelection active={activeSection === 'contests'}>Contests</SideBarSelection>
                        </SideBar>
                        <PageContent>
                            {activeSection === 'contests' && (
                                <React.Fragment>
                                    <ContentWrapper>Contest Entries</ContentWrapper>
                                    {contestList.length > 0 && (
                                        <select value={activeContest} onChange={(e)=>handleContestSelect(e)}>
                                            {contestList.map((contest, index) => 
                                                <option key={index} value={contest}>{contest}</option>
                                            )}
                                        </select>
                                    )}
                                    <Button style={{marginTop: 20}} onClick={handleContestCopy}>Copy Results To Clipboard</Button>
                                    {contestResults.length > 0 && (
                                        <React.Fragment>
                                            <p style={{margin: 0}}>{contestResults.length} entries copied to clipboard.</p>
                                            <p style={{margin: 0}}>Paste results <a href="https://wheelofnames.com" target="_blank">here</a> to choose a winner!</p>
                                        </React.Fragment>
                                    )}
                                </React.Fragment>
                            )}
                        </PageContent>
                </Container>
            )}
        </React.Fragment>
    )
}

const SideBar = styled.div`
    height: 100%;
    border-right: 2px solid #666;
    width: auto;
    display: inline-block;
    float: left;
    padding-right: 10px;
`
const SideBarSelection = styled.p`
    // padding-top: 15px;
    font-size: 14px;
    margin: 5px 0;
    cursor: pointer;
    color: #000;
    font-weight: ${props => props.active ? 'bold' : 'normal'}
`

const Title = styled.h1`
    font-family: 'Bebas Neue', sans-serif;
    margin: 0;
    line-height: 1;
    margin-bottom: 20px;
`
const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 3px 10px;
    box-sizing: border-box;
`

const PageContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
`

const mapStateToProps = (state) => {
    return {
        admin: state.user.profile.admin,
        authLoaded: state.user.authLoaded
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchContestList:() => dispatch(fetchContestList()),
        fetchContestData:(contestId) => dispatch(fetchContestData(contestId))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminPage)