import React from 'react';
import {Elements} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import SubscriptionForm from './SubscriptionForm';

const STRIPE_API_KEY = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_STRIPE_PROD_API_KEY : process.env.REACT_APP_STRIPE_TEST_API_KEY;
const STRIPE_PROMISE = loadStripe(STRIPE_API_KEY);

function StripeForm(props) {
    return (
        <Elements stripe={STRIPE_PROMISE}>
            <SubscriptionForm 
                unlockIntroPricing={()=>props.unlockIntroPricing()}
                unlockHalfOffPricing={()=>props.unlockHalfOffPricing()}
                resetPricing={()=>props.resetPricing()}
            />
        </Elements>
    );
}

export default StripeForm;

