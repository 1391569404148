import React from 'react'
import styled from 'styled-components'
import {LoadingIcon} from './Icons'

const ButtonContainer = styled.button`
    padding: ${props => props.small ? '5px 10px' : '10px 20px'};
    background-color: ${props => props.secondary ? '#00000085':'#FEB802'};
    color: ${props => props.secondary ? '#fff':'#000'};
    width: ${props => props.fullWidth ? '100%' : 'auto'};
    align-self: flex-start;
    min-width: 200px;
    text-align: center;
    border-radius: 12px;
    font-family: 'Bebas Neue', sans-serif;
    font-size: ${props => props.small ? '14px' : '20px'};
    letter-spacing: 1px;
    transition: background-color .3s ease-out;
    cursor: pointer;
    display: inline-block;
    display: flex;
    justify-content: center;
    border: none;

    :hover {
        background-color: ${props => props.secondary ? '#00000099':'#e4a501'};
    }

    &:focus {
        outline: none;
    }
`

const Button = (props) => {
    return (
        <ButtonContainer onClick={props.onClick} style={props.style} secondary={props.secondary} fullWidth={props.fullWidth} {...props}> 
            {props.loading === "true" ? (
                <LoadingIcon/>
            ) : (
                <React.Fragment>
                    {props.children}
                </React.Fragment>
            )}
        </ButtonContainer>
    )
}

export default Button
