import React from 'react';
import ReactDOM from 'react-dom';
import './normalize.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
// Importing the Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-notifications/lib/notifications.css';
import './fonts.css'

import { Provider } from 'react-redux'
import thunk from 'redux-thunk'
import { createStore, compose, applyMiddleware } from 'redux'
import rootReducer from './store/reducers'
import { ReactReduxFirebaseProvider, getFirebase} from 'react-redux-firebase';
import 'firebase/auth'
import 'firebase/firestore'
import firebase from 'firebase/app'
import { createFirestoreInstance, getFirestore} from 'redux-firestore'
import ReactGA from 'react-ga';

import * as Sentry from '@sentry/react'
import {Integrations} from '@sentry/tracing'


Sentry.init({
  dsn: "https://b68b383ee53f47a7ab9b19106a4d16ea@o453765.ingest.sentry.io/5442793",
  integrations: [
    new Integrations.BrowserTracing(),
  ],
  tracesSampleRate: 1.0,
});

const middlewares = [thunk.withExtraArgument(getFirebase, getFirestore)]
const store = createStore(rootReducer, compose(applyMiddleware(...middlewares)))

const rrfConfig = {
  userProfile: 'users',
  useFirestoreForProfile: true // Firestore for Profile instead of Realtime DB
}

// const rrfProps = {
//   firebase,
//   config: rrfConfig,
//   dispatch: store.dispatch,
//   createFirestoreInstance
// }

const fbConfig = {
  apiKey: "AIzaSyD5Zz2fU6ULePlHgFWybq1eCoFUvnsA508",
  authDomain: "poddecks.firebaseapp.com",
  databaseURL: "https://poddecks.firebaseio.com",
  projectId: "poddecks",
  storageBucket: "poddecks.appspot.com",
  messagingSenderId: "121159515657",
  appId: "1:121159515657:web:cb81ca03b9278735ad82d8",
  measurementId: "G-2FYMGSXR9V"
}

// Initialize firebase instance
firebase.initializeApp(fbConfig)

// Initaizlie Google Analytics
ReactGA.initialize('UA-154619056-1');
ReactGA.pageview(window.location.pathname + window.location.search);

ReactDOM.render(
  <React.Fragment>
    <Provider store={store}>
      <ReactReduxFirebaseProvider
        // {...rrfProps}
        firebase={firebase}
        config={rrfConfig}
        dispatch={store.dispatch}
        createFirestoreInstance={createFirestoreInstance}
        >
        <App />
      </ReactReduxFirebaseProvider>
    </Provider>
  </React.Fragment>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
