import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {Container, Modal} from 'react-bootstrap'
import {connect} from 'react-redux'
import { useHistory } from "react-router-dom";
import {toggleSubscription, checkSubscriptionStatus} from '../store/actions/userActions'
import moment from 'moment'
import Button from '../Components/Button'
import CloseButton from '../Components/CloseButton'
import {LoadingIcon} from '../Components/Icons'

const ProfilePage = (props) => {
    const history = useHistory()
    const [subscriptionType, setSubscriptionType] = useState('')
    const [cancelConfirm, setCancelConfirm] = useState(false)
    const [cancelError, setCancelError] = useState(false)
    const [cancelLoading, setCancelLoading] = useState(false)
    const [isTrialPeriod, setIsTrialPeriod] = useState(false)
    const [nextBillingDate, setNextBillingDate] = useState('')
    const [unsubscribeDetected, setUnsubscribeDetected] = useState(false)
    const [profileLoading, setProfileLoading] = useState(false)

    const handleCancelClick = () => {
        if (props.subscribeData.subscriber.management_url) {
            const win = window.open(props.subscribeData.subscriber.management_url, '_blank');
            win.focus()
        }
        else {
            setCancelConfirm(true)
        }
    }

    const toggleSubscription = async () => {
        setCancelLoading(true)
        setCancelError(false)
        try {
            const status = await props.toggleSubscription()
            console.log('status: ', status)
            setCancelLoading(false)
            setCancelConfirm(false)
            setProfileLoading(true)
            setTimeout(()=>{
                props.checkSubscriptionStatus(props.uid)
            }, 2000)
            
        }
        catch (error) {
            console.log('there is an error: ', error)
            setCancelError(error)
            setCancelLoading(false)
        }
    }

    const checkIfFreeTrial = () => {
        if (props.subscribed) {
            const activeSub = props.subscribeData.subscriber.entitlements.full_access.product_identifier
            if (props.subscribeData.subscriber.subscriptions[activeSub]) {
                if (props.subscribeData.subscriber.subscriptions[activeSub].period_type === "trial") {
                    setIsTrialPeriod(true)
                }
            }   
        }
    }

    const getNextBillingDate = () => {
        setNextBillingDate(moment(props.subscribeData.subscriber.entitlements.full_access.expires_date).utc().format('LL'))
    }

    const checkIfUnsubscribed = () => {
        setUnsubscribeDetected(props.subscribeData.subscriber.subscriptions[props.subscribeData.subscriber.entitlements.full_access.product_identifier].unsubscribe_detected_at ? true: false)
    }

    useEffect(()=>{
        if (props.subscribed) {
            checkIfFreeTrial()
            getNextBillingDate()
            checkIfUnsubscribed()
            setProfileLoading(false)
            //GET TYPE OF SUBSCRIPTION 
            switch (props.subscribeData.subscriber.entitlements.full_access.product_identifier) {
                case 'prod_I18mNH8d2XXkyP':
                    setSubscriptionType('Monthly')
                    break;
                case 'prod_HvWYqvstLrrQkI':
                    setSubscriptionType('Monthly')
                    break;
                case 'monthly_subscription':
                    setSubscriptionType('Monthly')
                    break;
                case 'prod_HvWfC7XMQjYCS4':
                    setSubscriptionType('Annual')
                    break;
                case 'prod_I18mcaKzdjDrYS':
                    setSubscriptionType('Annual')
                    break;
                case 'annual_subscription':
                    setSubscriptionType('Annual')
                    break;
                default:
                    break;
            }
        }
    }, [props.subscribeData, props.subscribed])

    useEffect(()=>{
        props.checkSubscriptionStatus(props.uid)
    },[])

    useEffect(()=>{
        if (!props.uid && props.authLoaded) {
            history.push('/')
        }
        else {
            setProfileLoading(true)
        }
    }, [])

    useEffect(()=>{
        if (!props.uid && props.authLoaded) {
            history.push('/')
        }
        else {
            setProfileLoading(true)
        }
    }, [props.authLoaded])

    return (
        <Container style={{marginTop: 10}}>
            <Heading>
                {props.profile ? (
                    <React.Fragment>
                        {props.profile.profilePicture && (<ProfileImage src={props.profile.profilePicture} alt="Profile Picture"/>)}
                        <Title>{props.profile.name ? props.profile.name : profileLoading ? 'Account' : 'Account'}</Title>
                    </React.Fragment>
                ) : (
                    <Title>Account</Title>
                )}
            </Heading>
            {profileLoading ? <LoadingIcon/> : (
                <React.Fragment>
                     <EntryContainer>
                        <Label>Email</Label>
                        <Entry>{props.profile.emailAddress}</Entry>
                    </EntryContainer>
                    <EntryContainer>
                        <Label>Subscription Status</Label>
                        <Entry>{props.subscribed ? 'Subscribed' : 'Not Subscribed'}</Entry>
                    </EntryContainer>
                    {props.subscribed && (
                        <React.Fragment>
                            <EntryContainer>
                                <Label>Subscription Type:</Label>
                                <Entry>{subscriptionType}</Entry>
                            </EntryContainer>
                            {isTrialPeriod && (
                                <EntryContainer>
                                    <Label>Free Trial</Label>
                                    <Entry>Active</Entry>
                                </EntryContainer>
                            )}
                            {!unsubscribeDetected && (
                                <EntryContainer>
                                    <Label>Next billing date:</Label>
                                    <Entry>{nextBillingDate}</Entry>
                                </EntryContainer>
                            )}
                            <EntryContainer>
                                <Entry>Auto Renewal: {unsubscribeDetected ? 'Off' : 'On'}</Entry>
                            </EntryContainer>
                            {props.subscribed && (
                                <EntryContainer>
                                    <Button onClick={handleCancelClick}>{props.subscribeData.subscriber.management_url ? 'Manage Subscription' : `Turn ${unsubscribeDetected ? 'On' : 'Off'} Auto-Renewal`}</Button>
                                </EntryContainer>
                            )}
                        </React.Fragment>
                    )}
                </React.Fragment>
            )}

            <Modal show={cancelConfirm} onHide={()=>{setCancelConfirm(false); setCancelError('')}}>
                <CloseButton onClick={()=>{setCancelConfirm(false); setCancelError('')}}/>
                <ModalContainer>
                    {unsubscribeDetected ? (
                        <React.Fragment>
                            <CancelText>Turn Auto-Renewal Back On?</CancelText>
                            <p style={{color: "#fff", textAlign: 'center', fontSize: 14}}>Your card will be charged on <br/>{nextBillingDate}</p>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <CancelText>Are you sure want to turn off your Pod Decks<sup style={{fontSize: '50%', top: -10}}>&trade;</sup> App Subscription Auto-Renewal?</CancelText>
                            <p style={{color: "#fff", textAlign: 'center', fontSize: 14}}>You will still have access to your subscription until: <br/>{nextBillingDate}</p>
                        </React.Fragment>
                    )}
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginTop: 15}}>
                        <Button onClick={toggleSubscription} loading={cancelLoading}>Turn {unsubscribeDetected ? 'On' : 'Off'} Auto-Renewal</Button>
                        <Button onClick={()=>setCancelConfirm(false)} style={{marginLeft: 15}} secondary>Nevermind</Button>
                    </div>
                <ErrorText>{cancelError && 'Error: '}{cancelError}</ErrorText>
                </ModalContainer>
            </Modal>

        </Container>
    )
}

const Title = styled.h1`
    font-family: 'Bebas Neue', sans-serif;
    margin: 0;
    line-height: 1;
`

const EntryContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 15px;
`

const Entry = styled.p`
    font-size: 14px;
    margin: 0;
`

const Label = styled.p`
    font-size: 16px;
    color: #141414;
    margin-bottom: 0;
    text-decoration: underline;
`

const ProfileImage = styled.img`
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
`

const Heading = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 20px;
    margin-top: 20px;
`

const CancelText = styled.h3`
    font-size: 1.4rem;
    color: #fff;
    text-align: center;
`

const ModalContainer = styled.div`
    background-color: #1E1E1E;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
`

const ErrorText = styled.p`
    color: red;
    font-size: 14px;
    margin-top: 15px;
`

const mapStateToProps = (state) => {
    return {
        profile: state.user.profile,
        uid: state.firebaseReducer.auth.uid,
        subscribed: state.user.subscribeStatus,
        subscribeData: state.user.subscribeData,
        authLoaded: state.user.authLoaded
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
      checkSubscriptionStatus: (uid) => dispatch(checkSubscriptionStatus(uid)),
      toggleSubscription: () => dispatch(toggleSubscription())
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage)
