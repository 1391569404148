import React from 'react'
import styled from 'styled-components'
import {InstagramIcon, FacebookIconSmall, YouTubeIcon} from '../Components/Icons'

const Footer = (props) => {
    return (
        <FooterContainer>
            <PodDecksIcon src={require('../assets/img/poddecks-logo.png')}/>
            <SocialMediaContainer>
                <a style={{marginRight: 15}} href="https://instagram.com/poddecks" target="_blank" rel="noopener noreferrer"><InstagramIcon/></a>
                <a style={{marginRight: 15}} href="https://facebook.com/poddecks" target="_blank" rel="noopener noreferrer"><FacebookIconSmall/></a>
                <a href="https://www.youtube.com/channel/UCWuvmFMv-O7o4Wse4fRdyag" target="_blank" rel="noopener noreferrer"><YouTubeIcon/></a>
            </SocialMediaContainer>
            <FooterText>&copy; 2020 Pod Decks Digital, LLC</FooterText>
            <FooterText>
                <FooterLink style={{marginRight: 10}} href="https://poddecks.com/terms" target="_blank" rel="noopener noreferrer">Terms &amp; Conditions</FooterLink>
                <FooterLink style={{marginRight: 10}} href="https://poddecks.com/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</FooterLink>
                <FooterLink style={{marginRight: 10}} href="https://poddecks.zendesk.com/hc/en-us" target="_blank" rel="noopener noreferrer">Support</FooterLink>
                <FooterLink href="https://poddecks.zendesk.com/hc/en-us/sections/360010500792-FAQ" target="_blank" rel="noopener noreferrer">FAQ</FooterLink>
            </FooterText>
        </FooterContainer>
    )
}

const FooterContainer = styled.div`
    background-color: #1E1E1E;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 35px 0;
    box-sizing: border-box;
    margin-top: auto;
`

const SocialMediaContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    jusitfy-content: center;
    align-self: center;
    margin-top: 10px;
    margin-bottom: 25px;
`

const PodDecksIcon = styled.img`
    width: 150px;
`

const FooterText = styled.p`
    color: #DCDCDC;
    text-align: center;
    margin: 0;
    font-size: 14px;
`

const FooterLink = styled.a`
    color: #FEB802;
    font-size: 12px;

    &:hover {
        color: #FEB802;
    }
`

export default Footer