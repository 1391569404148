import React from 'react'
import styled from 'styled-components'
import AppStoreIcon from '../assets/img/app-store-icon.svg'
import PlayIcon from '../assets/img/google-play-badge_small.png'
import ReactGA from 'react-ga';

const DownloadSection = () => {
    
    const logEvent = (action, category) => {
        ReactGA.event({
            category: category,
            action: action
        });
        window.fbq('trackCustom', action)
    }

    return (
        <Container>
            <Title>Download the Pod Decks App</Title>
            <AppStoreLinkContainer>
                <AppStoreLink href="https://apps.apple.com/us/app/id1514611595" onClick={()=>logEvent('AppleAppStoreClick', 'User')} target="_blank" rel="noopener noreferrer"><AppStoreImage alt="Download on the App Store" src={AppStoreIcon}/></AppStoreLink>
                <AppStoreLink href="https://play.google.com/store/apps/details?id=com.poddecks" onClick={()=>logEvent('GooglePlayStoreClick', 'User')} target="_blank" rel="noopener noreferrer"><PlayStoreImage alt="Get on Google Play" src={PlayIcon}/></AppStoreLink>
            </AppStoreLinkContainer>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 10px;
    background-color: #F1F1F1;

    @media(max-width: 768px) {
        background-color: #fff;
    }
`

const Title = styled.h3`
    font-family: 'Bebas Neue', sans-serif;
    font-size: 35px;
`

const AppStoreLinkContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    @media(max-width: 425px) {
        flex-direction: column;
    }
`

const AppStoreLink = styled.a`
    margin-top: 0rem;

    @media(max-width: 425px) {
        margin-top: .5rem;
    }
`

const AppStoreImage = styled.img`
    width: 11rem;
`

const PlayStoreImage = styled.img`
    width: 14rem;

    @media(max-width: 425px) {
        width: 13rem;
    }
`

export default DownloadSection