let SUBSCRIPTION_MAP = {}
if (process.env.NODE_ENV === "development") {
    SUBSCRIPTION_MAP = {
        // SANDBOX
        annual: 'price_1HR6V0DT1yOz1PecxnpX9V6R',
        monthly: 'price_1HR6VNDT1yOz1PeceKtcqhuv',
        annualIntro: 'price_1HruXVDT1yOz1Peci7znQpGq',
        monthlyIntro: 'price_1HTgPMDT1yOz1Pecdw8D0qSZ',
        annualHalf: 'price_1IYwzPDT1yOz1PecT6dOr2o7',
        monthlyHalf: 'price_1IYwyiDT1yOz1PecHb0J7uo7'
    };
}
else if (process.env.NODE_ENV === "production") {
    SUBSCRIPTION_MAP = {    
        //PRODUCTION
        annual: 'price_1HUzAYDT1yOz1PectxJD4WM5',
        monthly: 'price_1HLfVTDT1yOz1PecryazvWYh',
        annualIntro: 'price_1HruWXDT1yOz1PeceCvZZJQe',
        monthlyIntro: 'price_1HTgNtDT1yOz1PecE5xhfKic',
        annualHalf: 'price_1IYwx3DT1yOz1PecuKPirQGt',
        monthlyHalf: 'price_1IYwyHDT1yOz1Pecl7T0eiNu',
    };
}

export default SUBSCRIPTION_MAP