import React, {useState} from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import Button from './Button'
import {signUpWithEmailAndPassword, signInWithGoogle, signInWithFacebook} from '../store/actions/userActions'
import {GoogleIcon, FacebookIcon, EyeIcon} from './Icons'
import CloseButton from '../Components/CloseButton'

const LoginForm = (props) => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [name, setName] = useState('')
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const [passwordProtect, setPasswordProtect] = useState(true)

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        await props.signUpWithEmailAndPassword(email, password, name)
        .then( resp => {
            if (resp.success) {
                props.hideForm()
            }
        })
        .catch(error => setError(error))
        .finally(()=> {setLoading(false)})
    }

    const handleGoogleSignIn = async(e) => {
        e.preventDefault()
        setLoading(true)
        await props.signInWithGoogle()
        .then( resp => {
            if (resp.success) {
                props.hideForm()
            }
        })
        .catch(error => setError(error))
        .finally(()=> {setLoading(false)})
    }

    const handleFacebookSignIn = (async(e) => {
        e.preventDefault()
        setLoading(true)
        await props.signInWithFacebook()
        .then( resp => {
            if (resp.success) {
                props.hideForm()
            }
        })
        .catch(error => setError(error))
        .finally(()=> {setLoading(false)})
    })

    return (
        <Wrapper>
            <CloseButton onClick={props.hideForm}/>
            <Container>
                <Title>Sign Up</Title>
                <Description>To get started with your Pod Decks App subscription, we'll need to create an account.</Description>
                <LinkContainer>
                    <p style={{color: '#FEB802'}}>Already have an account? <a onClick={props.openLoginForm} style={{textDecoration: 'underline', cursor: 'pointer'}}>Log In</a></p>
                </LinkContainer>
                <Form onSubmit={(e)=>handleSubmit(e)} onFocus={()=>setError('')}>
                    <Input type="email" placeholder="Email" onChange={(e)=>{setEmail(e.target.value)}} required/>
                    <PasswordWrapper>
                        <Input type={passwordProtect ? "password" : "text"} placeholder="Password" style={{marginBottom: 0, paddingRight: 30}} onChange={(e)=>{setPassword(e.target.value)}} required/>
                        <TogglePasswordLink onClick={()=>setPasswordProtect(!passwordProtect)} active={passwordProtect ? false : true}>
                            <EyeIcon/>
                        </TogglePasswordLink>
                    </PasswordWrapper>
                    <InputContainer>
                        <Input type="text" placeholder="Your Name" onChange={(e)=>{setName(e.target.value)}}/>
                        <OptionalText>Optional</OptionalText>
                    </InputContainer>
                    <Button fullWidth loading={loading}><SubmitInput type="submit" value="Sign Up"/></Button>
                    <Error>{error}</Error>
                </Form>
                <Separator>Or</Separator>
                <ProviderTitle>Sign In With:</ProviderTitle>
                <ProviderContainer>
                    <ProviderSignIn style={{marginRight: 20}} onClick={handleGoogleSignIn}>
                        <GoogleIcon size="35"/>
                    </ProviderSignIn>
                    <ProviderSignIn onClick={handleFacebookSignIn}>
                        <FacebookIcon size="35"/>
                    </ProviderSignIn>
                </ProviderContainer>
            </Container>
        </Wrapper>
    )
}

const Title = styled.h3`
    font-size: 45px;
    margin-bottom: 10px;
    color: #fff;
    font-family: 'Bebas Neue', sans-serif;
    text-align: center;
`

const Wrapper = styled.div`
    padding: 20px;
    background-color: #1E1E1E;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const Separator = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: #fff;
    margin-top: 20px;
    margin-bottom: 10px;
    font-family: 'Bebas Neue', sans-serif;

    &:before, &:after {
        content: '';
        width: 40%;
        height: 1px;
        background-color: #fff;
    }

    &:before {
        margin-right: auto;
    }

    &:after {
        margin-left: auto;
    }
`

const Container = styled.div`
    display: flex;
    flex-direction: column;
    aling-items: center;
    justicy-content: center;
    max-width: 350px;
    width: 100%;
`

const Description = styled.p`
    color: #fff;
    font-weight: 300;
    text-align: center;
    margin-bottom: 10px;
`

const Form = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const Input = styled.input`
    color: #000;
    background: #fff;
    border-radius: 5px;
    width: 100%;
    padding: 10px;
    border: none;
    margin-bottom: 20px;

    &:focus {
        outline: none;
    }
`

const InputContainer = styled.div`
    position: relative;
    width: 100%;
    margin-bottom: 5px;
`

const OptionalText = styled.p`
    font-size: 12px;
    color: #fff;
    margin: 0;
    padding: 0;
    position: absolute;
    bottom: 0px;
    left: 0px;
`


const SubmitInput = styled.input`
    background-color: transparent;
    border: none;

    &:focus {
        outline: none;
    }
`

const ProviderContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`

const ProviderSignIn = styled.a`
    border: 3px solid #FEB802;
    padding: 10px;
    cursor: pointer;
`

const ProviderTitle = styled.h5`
    color: #fff;
    font-size: 15px;
    text-align: center;
    margin-bottom: 10px;
`

const LinkContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
`

const Link = styled.a`
    color: #FEB802;
    font-size: 13px;
    cursor: pointer;
    text-decoration: none;

    &:hover {
        color: #FEB802;
        text-decoration: none;
    }
`

const Error = styled.p`
    color: red;
    font-size: 13px;
    margin-top: 10px;
    margin-bottom: 0;
`

const PasswordWrapper = styled.div`
    position: relative;
    width: 100%;
    margin-bottom: 20px;
    cursor: pointer;
`

const TogglePasswordLink = styled.div`
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    opacity: ${props => props.active ? 1 : .6}
`


const mapDispatchToProps = (dispatch) => {
    return {
        signUpWithEmailAndPassword:(email, password, name) => dispatch(signUpWithEmailAndPassword(email, password, name)),
        signInWithGoogle: () => dispatch(signInWithGoogle()),
        signInWithFacebook: () => dispatch(signInWithFacebook())
    }
}

export default connect(null, mapDispatchToProps)(LoginForm)

// export default LoginForm