import React, {useState, useEffect, useRef} from 'react';
import Button from '../Button';
import {CardElement, useStripe, useElements} from '@stripe/react-stripe-js';
import styled from "styled-components";
import {checkSubscriptionStatus} from '../../store/actions/userActions'
import {connect} from "react-redux";
import moment from 'moment'
import ReactGA from 'react-ga';
import {LoadingIcon} from '../Icons'


const SubscriptionForm = (props) => {
    // const [activeOption, setActiveOption] = useState('annual');
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)
    const [errorText, setErrorText] = useState('')
    // const [couponText, setCouponText] = useState('')
    const [couponError, setCouponError] = useState('')
    const [couponSuccess, setCouponSuccess] = useState('')
    const [activeCoupon, setActiveCoupon] = useState('')
    const [preLaunch, setPreLaunch] = useState(false)
    const [billingDate, setBillingDate] = useState(false)
    const [returningUser, setReturningUser] = useState(false)
    const [subscriberLoading, setSubscriberLoading] = useState(false)

    const stripe = useStripe();
    const elements = useElements();

    const couponTimer = useRef(null)

    // const { name } = props.profile;
    const uid = props.uid;
    const email = props.email

    const couponData = {
        'travissentme': {
            startDate: moment('January 16 2020'),
            endDate: moment('January 16 2099'),
            introPricing: true
        },
        'welcome50': {
            startDate: moment('March 24 2020'),
            endDate: moment('March 24 2099'),
            halfPricing: true
        }
    }

    useEffect(()=>{
        if (!props.subscribeData.subscriber) {
            setSubscriberLoading(true)
        }
        else {
            setSubscriberLoading(false)
        }

    }, [props.subscribeData])

    useEffect(()=>{
        if (props.subscribeData.subscriber) {
            //CALC PRE LAUNCH
            const today = moment()
            console.log('today: ', today)
            const launchDay = moment('September 30, 2020')
            const isPreLaunch = today.isBefore(launchDay)
            const isReturningUser = props.subscribeData.subscriber.entitlements.full_access ? true : false
            setReturningUser(isReturningUser)
            setPreLaunch(isPreLaunch)
            //CALC BILLING DATE
            if (isPreLaunch) {
                setBillingDate('October 7, 2020')
            }
            else {
                setBillingDate(moment().add(8, 'days').format('LL'))
            }
        }
    }, [props.subscribeData])    

    const handleSubmit = (event) => {
        ReactGA.event({
            category: 'User',
            action: 'Subscription Purchase Submit'
        });
        setLoading(true)
        event.preventDefault();
        // Use elements.getElement to get a reference to the mounted Element.
        const cardElement = elements.getElement(CardElement);

        // Pass the Element directly to other Stripe.js methods:
        // e.g. createToken - https://stripe.com/docs/js/tokens_sources/create_token?type=cardElement
        stripe.createToken(cardElement)
            .then((result) => {
                if (result.error) {
                    throw result.error.message;
                } else {
                    const body = {
                        user_id: uid,
                        user_email: email,
                        user_name: props.profile.name,
                        plan_id: props.activePlanChoice.priceId,
                        token: result.token
                    };

                    if (activeCoupon) {
                        body['coupon_code'] = activeCoupon
                    }

                    if (activeCoupon && couponData[activeCoupon].extendedTrial) {
                        body['extended_trial'] = couponData[activeCoupon].extendedTrial
                    }

                    if (activeCoupon && couponData[activeCoupon].affiliate) {
                        body['affiliate'] = activeCoupon
                    }

                    fetch('https://us-central1-poddecks.cloudfunctions.net/subscribe', {
                    // fetch('http://localhost:5001/poddecks/us-central1/subscribe', {
                        method: 'post',
                        body: JSON.stringify(body)
                    }).then(function(response) {
                        return response.json();
                    }).then(function(data) {
                        if (data.statusCode === 200) {
                            ReactGA.event({
                                category: 'User',
                                action: 'Subscription Purchase Success'
                            });
                            setSuccess(true)
                            props.checkSubscriptionStatus(props.uid)
                        }
                        else {
                            setErrorText(JSON.parse(data.body).error)
                        }
                        setLoading(false)
                    });

                }

            })
            .catch(err => {
                console.log('[error]: ', err)
                setErrorText(err)
                setLoading(false)
            })
    };

    const handleCouponTextChange = (e) => {
        clearTimeout(couponTimer.current)
        const coupon = e.target.value
        setCouponError('')
        setCouponSuccess('')
        // setCouponText(coupon)
        couponTimer.current = setTimeout(()=>{
            validateCoupon(coupon.toLowerCase())
        }, 1000)
    }

    const validateCoupon = (coupon) => {
        console.log('validating coupon: ', coupon)
        setCouponError('')
        setCouponSuccess('')

        const today = moment()

        if (couponData[coupon]) {
            console.log('coupon found')
            if (!today.isBefore(couponData[coupon].startDate) && !today.isAfter(couponData[coupon].endDate)) {
                setActiveCoupon(coupon)
                if (couponData[coupon].extendedTrial) {
                    setCouponSuccess('Extended Free Trial Applied')
                    props.resetPricing()
                    setBillingDate(moment().add(couponData[coupon].extendedTrial, 'days').format('LL'))
                }
                else if (couponData[coupon].introPricing) {
                    setCouponSuccess('Discount Applied')
                    props.unlockIntroPricing()
                }
                else if (couponData[coupon].halfPricing) {
                    setCouponSuccess('Discount Applied')
                    props.unlockHalfOffPricing()
                }
                else if (couponData[coupon].affiliate) {
                    setCouponSuccess('Promo Redeemed')
                }
            }
            else {
                console.log('not valid yet')
                setCouponError('Invalid Promo Code')    
                props.resetPricing()
            }
        }
        else {
            setCouponError('Invalid Promo Code')
            props.resetPricing()
        }
    }

    return (
        <React.Fragment>
            {subscriberLoading ? (
                <LoadingIcon/>
            ) : (
                <React.Fragment>
                    {success ? (
                        <React.Fragment>
                            <Heading>
                                <Title>Success!</Title>
                            </Heading>
                            <p>You have successfully subscribed to the Pod Decks&trade; App! Download it from the <a href="https://apps.apple.com/us/app/id1514611595" target="_blank" rel="noopener noreferrer">App Store</a> or <a href="https://play.google.com/store/apps/details?id=com.poddecks">Google Play</a>.</p>
                            <p>Once in the app, go to settings, select "Log In / Sign up", Log into your account that you just created, and then enjoy the Pod Decks&trade; App!</p>
                        </React.Fragment>
                    ) : (
                        <Form onSubmit={handleSubmit}>
                            <Heading>
                                <Title>Checkout</Title>
                            </Heading>
                            <FieldLabel>Plan Choice:</FieldLabel>
                            <FieldContent>{props.activePlanChoice.planName}</FieldContent>
                            <FieldLabel>Plan Price:</FieldLabel>
                            <FieldContent>{props.activePlanChoice.priceText}</FieldContent>
                            <FieldLabel>Promo Code:</FieldLabel>
                            <CouponWrapper>
                                <Input onChange={(e) => {handleCouponTextChange(e)}}></Input>
                                {couponSuccess && <SuccessText>{couponSuccess}</SuccessText>}
                                {couponError && <ErrorText>{couponError}</ErrorText>}
                            </CouponWrapper>
                            <FieldLabel style={{marginBottom: 5}}>Credit Card:</FieldLabel>
                            <CardContainer>
                                <CardElement
                                    options={CARD_OPTIONS}
                                />
                            </CardContainer>
                            {returningUser ? (
                                <FieldContent style={{fontSize: 12, marginBottom: 10}}>Since you have already used your free trial, you will be billed immediately. After that, you will be billed every {props.activePlanChoice.planName === 'Monthly' || props.activePlanChoice.planName === "Monthly: Pre-Launch Pricing" ? 'month' : '12 months'}.</FieldContent>
                            ) : (
                                <FieldContent style={{fontSize: 12, marginBottom: 10}}>You will be billed on {billingDate} after your {activeCoupon && couponData[activeCoupon].extendedTrial ? couponData[activeCoupon].extendedTrial : '7'} day Free Trial{preLaunch && " begins on September 30th"}. After that, you will be billed every {props.activePlanChoice.planName === 'Monthly' || props.activePlanChoice.planName === "Monthly: Pre-Launch Pricing" ? 'month' : '12 months'}.</FieldContent>
                            )}
                            <FieldContent style={{fontSize: 12, marginBottom: 10}}>Applicable VAT, sales, or other taxes may apply. <a href="https://poddecks.zendesk.com/hc/en-us/articles/360049681812-How-do-I-cancel-my-subscription-" target="_blank" rel="noopener noreferrer">Cancel anytime</a>. View Our <a href="https://poddecks.com/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>. </FieldContent>
                            <Button type="submit" disabled={!stripe} loading={loading ? "true" : "false"}>Buy Now</Button>
                            {errorText && (<ErrorText>{errorText}</ErrorText>)}
                        </Form>
                    )}
                    
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        profile: state.user.profile,
        uid: state.firebaseReducer.auth.uid,
        email: state.firebaseReducer.auth.email,
        activePlanChoice: state.user.activePlanChoice,
        subscribeData: state.user.subscribeData
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
      checkSubscriptionStatus: (uid) => dispatch(checkSubscriptionStatus(uid))
    }
  }
  

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionForm)

const Form = styled.form`
    width: 100%;
    padding: 20px;
`;

const CardContainer = styled.div`
    max-width: 450px;
    border: 4px solid #FEB80290;
    padding: 20px;
    border-radius: 25px;
    margin-bottom: 15px;
`

const Heading = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 20px;
    margin-top: 20px;
`

const Title = styled.h1`
    font-family: 'Bebas Neue', sans-serif;
    margin: 0;
    line-height: 1;
    text-align: left;
    align-self: flex-start;
    font-size: 50px;
`

const FieldLabel = styled.p`
  font-size: 20px;
  font-weight: bold;
  margin: 0;
`

const Input = styled.input`
  border-radius: 20px;
  border-color: rgb(33, 37, 41);
  padding: 10px;
  border-width: 3px;
  width: 100%;

  &:focus {
      outline: 0;
  }
`

const CouponWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 10px;
`

const FieldContent = styled.p`
  font-size: 18px;
  margin-top: 0;
  margin-bottom: 10px;
`

const ErrorText = styled.p`
  color: red;
  font-size: 12px;
  margin: 0;
`

const SuccessText = styled.p`
  color: green;
  font-size: 12px;
  margin: 0;
`

const CARD_OPTIONS = {
    style: {
        base: {
            color: 'rgb(33, 37, 41)',
            padding: '20px',
            fontWeight: 500,
            fontSize: '16px',
            fontSmoothing: 'antialiased',
        },
        invalid: {
            iconColor: 'red',
            color: 'red',
        },
    },
};
